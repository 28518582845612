import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

const Login = () => {
  const { handleGoogleLogin } = useContext(AuthContext);

  return (
    <div>
      <button onClick={() => handleGoogleLogin()}>
        Iniciar sesión con Google
      </button>
    </div>
  );
};

export default Login;