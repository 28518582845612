import React from 'react';  
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthContext';  // Contexto personalizado
import { GoogleOAuthProvider } from '@react-oauth/google'; // Importación de GoogleOAuthProvider

const root = ReactDOM.createRoot(document.getElementById('root'));

// Asegúrate de que el clientId sea el ID de cliente completo obtenido de Google Cloud Console.
const clientId = '1023448811521-sil6g6i0h0ulrill546jja24crjj02in.apps.googleusercontent.com';

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={clientId}>  {/* Proveedor de OAuth de Google */}
      <AuthProvider>  {/* Contexto de autenticación */}
        <App />
      </AuthProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

reportWebVitals();
