import React, { useContext } from 'react';
import logo from '../logo.png';
import '../App.css';
import { AuthContext } from '../context/AuthContext';
import Login from './Login';
import TestConnection from './TestConnection';

const App = () => {
  const { user, handleLogout } = useContext(AuthContext);

  return (
    <div className="App">
      {user ? (
        <div>
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Edita <code>src/App.js</code> y guarda para recargar.
            </p>
            <a
              className="App-link"
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Aprende React
            </a>
            {/* Integración del componente de prueba */}
            <TestConnection />
            <h1>Bienvenido, {user.name}</h1>
            <button onClick={handleLogout}>Cerrar sesión</button>
            {/* Aquí puedes agregar el resto de tu aplicación */}
          </header>
        </div>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default App;
