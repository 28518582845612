import React, { useState, useEffect } from 'react';

const TestConnection = () => {
    const [message, setMessage] = useState("Cargando...");

    useEffect(() => {
        fetch("/api/test")
            .then(response => response.json())
            .then(data => setMessage(data.message))
            .catch(error => setMessage("Error de conexión"));
    }, []);

    return (
        <div>
            <h1>Prueba de Conexión</h1>
            <p>{message}</p>
        </div>
    );
};

export default TestConnection;
