import React, { createContext, useState, useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Verificar si hay un usuario almacenado al cargar la aplicación
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setLoading(false);
  }, []);

  // Función para manejar el inicio de sesión con Google
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      // Enviar el token al backend para su validación
      fetch('/api/auth/google', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: tokenResponse.access_token }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.user) {
            setUser(data.user);
            localStorage.setItem('user', JSON.stringify(data.user));
          } else {
            console.error('Error en la autenticación:', data.message);
          }
        })
        .catch((error) => {
          console.error('Error al comunicarse con el backend:', error);
        });
    },
    onError: () => {
      console.error('Error en el inicio de sesión con Google');
    },
  });

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user');
    // Opcional: Llamar al backend para cerrar sesión si es necesario
  };

  return (
    <AuthContext.Provider value={{ user, loading, handleGoogleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
